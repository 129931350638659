<script>
import { Tooltip } from "bootstrap/dist/js/bootstrap";
import { ref, onMounted } from "vue";
export default {
  name: "AppTooltip",
  props: {
    customClass: {
      type: String,
      default: "",
    },
    tooltipMessage: {
      type: String,
    },
    placement: {
      type: String,
    },
  },
  setup(props) {
    const root = ref(null);

    onMounted(() => {
      new Tooltip(root.value, { customClass: props.customClass });
    });

    return {
      root,
    };
  },
};
</script>

<template>
  <section class="tooltip-container">
    <button
      ref="root"
      class="tooltip-button"
      data-bs-toggle="tooltip"
      :data-bs-placement="this.placement || 'top'"
      :title="this.tooltipMessage"
    >
      <slot />
    </button>
  </section>
</template>
